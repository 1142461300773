const axios = require('axios')

export const getDataURIFromString = async (img: string) => {
  const blob = await axios.get(img, { responseType: 'blob' })
  return await getDataURIFromFile(blob.data)
}

export const getDataURIFromFile = async (file: any) => {
  const dataURL = await new Promise((resolve, reject) => {
    const fr: any = new FileReader()
    fr.onload = () => {
      resolve(fr.result)
    }
    fr.onerror = reject
    fr.readAsDataURL(file)
  })

  return dataURL
}

export const checkAllowedFileExtensions = (file: File, allowedTypes: Array<String>) => {
  const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1)
  const isTypeAllowed = allowedTypes.find((type: String) => {
    return type.toLowerCase() === fileExtension.toLowerCase()
  })

  return isTypeAllowed
}
