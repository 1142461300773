import axios, { AxiosResponse } from 'axios'

export async function parseAxiosErrors<F extends () => Promise<AxiosResponse<any>>>(func: F): Promise<ReturnType<F> | AxiosResponse<any>> {
  try {
    return func()
  } catch (e: any) {
    console.error(e)

    if (axios.isAxiosError(e)) {
      return e.response as AxiosResponse<any>
    }

    throw new Error(e)
  }
}
