<template>
  <div>
    <alert-modal
      v-if="showSessionTimeoutModal"
      title="Your session is about to timeout"
      :text="`The current session is about to expire in <span class='timerCount'>${timerCount}</span> seconds. Would you like to continue the session?`"
      primaryBtnText="Stay connected"
      secondaryBtnText="Logout"
      @primaryBtnClick="stayConnectedHandler"
      @secondaryBtnClick="logOutHandler"
      @close="showSessionTimeoutModal = false"
    />
  </div>

</template>

<script>
import { mapActions } from 'vuex'
import AlertModal from '@/components/alert-modal.vue'

export default {
  components: {
    AlertModal
  },
  data () {
    return {
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
      logoutTimer: null,
      showSessionTimeoutModal: false,
      timerCount: 60,
      timer: null
    }
  },

  mounted () {
    this.events.forEach((e) => {
      window.addEventListener(e, this.resetTimer)
    })

    this.setTimer()
  },

  unmounted () {
    this.events.forEach((e) => {
      window.removeEventListener(e, this.resetTimer)
    })
  },
  destroyed () {
    clearTimeout(this.logoutTimer)
    clearInterval(this.timer)
  },
  methods: {
    ...mapActions('auth', ['logout', 'refresh']),

    logOutHandler: function () {
      // console.log('logOutHandler')
      clearTimeout(this.logoutTimer)

      this.logout()
      this.showSessionTimeoutModal = false
      this.$router.push({ path: '/login' })
    },

    stayConnectedHandler: function () {
      this.showSessionTimeoutModal = false
      clearTimeout(this.logoutTimer)
      this.setTimer()
    },

    setTimer: function () {
      this.logoutTimer = setTimeout(() => {
        this.showSessionTimeoutModal = true
      }, process.env.VUE_APP_IDLE_TIMEOUT_DURATION_IN_MINUTES * 1000 * 60) // 1 minute = 1000 * 60 * 1
    },

    startAutoLogoutTimerTimer: function () {
      this.timer = setInterval(() => {
        if (!this.timerCount--) {
          this.logOutHandler()
        }
      }, 1000)
    },

    resetTimer: function () {
      if (!this.showSessionTimeoutModal) {
        clearTimeout(this.logoutTimer)
        this.setTimer()
      }
    }
  },
  watch: {
    showSessionTimeoutModal (newValue, oldValue) {
      if (newValue) {
        this.startAutoLogoutTimerTimer()
      } else {
        clearInterval(this.timer)
        this.timerCount = 60
      }
    }
  }
}
</script>
