import axios from 'axios'
import axiosRetry from 'axios-retry'
import store from '@/store/store'
import parser from 'cron-parser'
import { cleanNotificationOpenSearchParams } from '@/helpers'

const axiosRetryInstance = axios.create({ baseURL: process.env.VUE_APP_API_URL })

axiosRetry(axiosRetryInstance, {
  retries: 3,
  retryCondition: (response) => response.statusCode !== 200
})

const getEvents = () => {
  return [
    'AgeOut',
    'AgeUp',
    'Enrolled- PP_CO_1',
    'Enrolled - CP_1',
    'Enrolled - MESA-1',
    'Activation Reminder Two',
    'Activation Reminder Three',
    'Activation Reminder Four',
    'Activation Reminder Five',
    'Activation Completed',
    'PCPChange',
    'ReferralAccepted'
  ]
}
const getTemplates = () => {
  return [
    'PP_CO_1',
    'PP_CO_2',
    'PP_CO_3',
    'PP_CO_4',
    'PP_CO_4A'
  ]
}
const getCommsPlatforms = () => {
  return [
    'MESA',
    'MESA - White Label',
    'Centivo Platform',
    'Open Access',
    'Gated'
  ]
}

const getBatchScheduledDate = async () => {
  // TODO: Add API once its created - while no API, using date based on cron expression
  // NOTE: batch jobs switched to using the scheduler to account for daylight savings time, which has an AWS specific cron expression.
  // Several options were explored but nothing found that didn't add unneeded complexity. Cron expression left hardcoded for now
  const utcDate = new Date().toUTCString()
  const cronExpression = '0 15 * * 3,5'
  const interval = parser.parseExpression(cronExpression, { currentDate: utcDate, utc: false, tz: 'America/New_York' })

  const scheduledDate = new Date(interval.next()).toLocaleString('en-US', {
    timeZone: 'America/New_York',
    day: '2-digit',
    month: 'long',
    weekday: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short'
  })

  return {
    scheduledDate: scheduledDate
  }
}

const BULK_SIZE = 2000;

const getBulkNotificationsData = async ({ filterParams, search, sortAttributes = {}, totalCount }) => {
  let notificationTotalList = []
  let searchAfter

  do {
    const response = await getNotificationsList({
      filterParams,
      search,
      searchAfter,
      sortAttributes,
      size: BULK_SIZE
    })

    notificationTotalList = [...notificationTotalList, ...response.notificationList]
    searchAfter = response.searchAfter
  } while (notificationTotalList.length < totalCount)

  return notificationTotalList
}

const getNotificationsList = async ({ cancelToken, filterParams, search, searchAfter, sortAttributes = {}, range, aggs, size, multiselect }) => {
  const params = {
    search,
    searchAfter,
    range,
    ...sortAttributes
  }

  if (aggs) {
    params.aggs = aggs
    // disabling this in CENG-1441 P1
    // params.timeZoneOffset = getTimezoneOffsetInHours()
  }

  if (size) {
    params.size = size
  }

  if (filterParams.status === 'PROCESSED' && !sortAttributes.sortField && !sortAttributes.sortDir) {
    params.sortField = 'sent'
    params.sortDir = 'desc'
  }

  if (multiselect) {
    params.multiselectAggs = true
  }

  for (const key in filterParams) {
    params[key] = filterParams[key]
  }

  const response = await axiosRetryInstance.get('/notifications/getAllTransactions', {
    params,
    headers: {
      Authorization: store.getters['auth/getAccessToken']
    },
    cancelToken
  })

  return response.data
}

const getNotificationErrorCount = async () => {
  const response = await axiosRetryInstance.get('/notifications/getAllTransactions', {
    params: {
      size: 0,
      status: 'ERROR'
    },
    headers: {
      Authorization: store.getters['auth/getAccessToken']
    }
  })

  return response.data.totalCount ?? 0
}

const getNotificationData = async ({ notification }) => {
  return await axios.get(`${process.env.VUE_APP_API_URL}/notifications/getNotificationData`, {
    params: {
      notification
    },
    headers: {
      Authorization: store.getters['auth/getAccessToken']
    }
  })
}

const sendNotifications = async ({ notification, filterParams = {}, search = {} }) => {
  const openSearchParams = cleanNotificationOpenSearchParams({
    ...filterParams,
    search
  })

  try {
    return await axios.post(`${process.env.VUE_APP_API_URL}/notifications/sendNotifications`, {
      notification,
      openSearchParams,
      modifiedBy: store.getters['auth/getUsername']
    }, {
      headers: {
        Authorization: store.getters['auth/getAccessToken']
      }
    })
  } catch (error) {
    const errorMessage = error?.response?.data
    return errorMessage
  }
}

const deleteNotification = async ({ notification, filterParams = {}, search = {} }) => {
  const openSearchParams = cleanNotificationOpenSearchParams({
    ...filterParams,
    search
  })

  try {
    return await axios.post(`${process.env.VUE_APP_API_URL}/notifications/deleteNotifications`, {
      notification,
      openSearchParams,
      modifiedBy: store.getters['auth/getUsername']
    }, {
      headers: {
        Authorization: store.getters['auth/getAccessToken']
      }
    })
  } catch (error) {
    const errorMessage = error?.response?.data
    return errorMessage
  }
}

const changeNotificationAutomation = async ({ notification, filterParams = {}, search = {}, sendType }) => {
  const openSearchParams = cleanNotificationOpenSearchParams({
    ...filterParams,
    search
  })

  try {
    return await axios.put(`${process.env.VUE_APP_API_URL}/notifications/changeNotificationAutomation`, {
      notification,
      openSearchParams,
      sendType
    }, {
      headers: {
        Authorization: store.getters['auth/getAccessToken']
      }
    })
  } catch (error) {
    const errorMessage = error?.response?.data
    return errorMessage
  }
}

const getNotificationsTemplateList = async () => {
  try {
    return await axiosRetryInstance.get('/notifications/getAllNotificationTemplates ', {
      headers: {
        Authorization: store.getters['auth/getAccessToken']

      }
    })
  } catch (error) {
    const errorMessage = error?.response?.data
    return errorMessage
  }
}

const updateTemplateList = async (template) => {
  try {
    return await axiosRetryInstance.post('/notifications/updateNotificationTemplateSendType', template)
  } catch (error) {
    const errorMessage = error?.response?.data
    return errorMessage
  }
}

export default {
  getBulkNotificationsData,
  changeNotificationAutomation,
  getEvents,
  getTemplates,
  getCommsPlatforms,
  getBatchScheduledDate,
  getNotificationData,
  getNotificationsList,
  sendNotifications,
  deleteNotification,
  getNotificationErrorCount,
  getNotificationsTemplateList,
  updateTemplateList
}
