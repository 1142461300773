import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import notificationsApi from '@/api/notificationsApi'

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    batchScheduledDate: null,
    cancelTokenSource: null,
    filterParams: {
      status: 'PENDING',
      sendType: 'automated'
    },
    searchParam: null,
    searchAfter: null,
    sortAttributes: null,
    queue: [],
    error: false,
    totalCount: 0,
    errorTabEnabled: false,
    aggregations: [],
    notificationTemplateList: []
  }
}

const notificationsQueue = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState())
    },
    setBatchScheduledDate(state, payload) {
      state.batchScheduledDate = payload
    },
    setCancelTokenSource(state, payload) {
      state.cancelTokenSource = payload
    },
    setSearchParam(state, payload) {
      state.searchParam = payload
    },
    setSearchAfter(state, payload) {
      state.searchAfter = payload
    },
    setFilterParams(state, payload) {
      state.filterParams = payload
    },
    setNotificationQueue(state, payload) {
      state.queue = payload
    },
    setSearchResults(state, payload) {
      state.searchResults = payload
    },
    setSortAttributes(state, payload) {
      state.sortAttributes = payload
    },
    setError(state, payload) {
      state.error = payload
    },
    setTotalCount(state, payload) {
      state.totalCount = payload
    },
    setAggregations(state, payload) {
      state.aggregations = payload
    },
    setTemplateList(state, payload) {
      state.notificationTemplateList = payload
    },
    addNotificationDetails(state, payload) {
      state.queue = state.queue.map((item) => {
        if (item.messageId === payload.notification.messageId) {
          item.details = payload.data
        }

        return item
      })
    },
    addToNotificationQueue(state, payload) {
      const updatedQueue = payload?.isScroll
        ? [...state.queue, ...payload?.notificationList]
        : [...payload?.notificationList]

      state.queue = updatedQueue
    },
    setErrorTabEnabled(state, payload) {
      state.errorTabEnabled = payload
    }
  },
  actions: {
    async getBulkNotificationsData({ state }) {
      const { filterParams, searchParam, sortAttributes, totalCount } = state

      const response = await notificationsApi.getBulkNotificationsData({
        filterParams,
        sortAttributes,
        totalCount,
        search: searchParam
      })

      return response
    },
    async getSearchResults({ commit, state }, { searchCriteria, filterParams = {}, sortAttributes = {}, aggs, multiselect = false }) {
      try {
        const { CancelToken } = axios
        const cancelTokenSource = CancelToken.source()

        commit('setCancelTokenSource', cancelTokenSource)
        commit('setSearchParam', searchCriteria)
        commit('setFilterParams', filterParams)
        commit('setSortAttributes', sortAttributes)

        const response = await notificationsApi.getNotificationsList({
          cancelToken: cancelTokenSource.token,
          filterParams: { ...state.filterParams, ...filterParams },
          search: searchCriteria,
          sortAttributes,
          aggs,
          multiselect
        })

        const { notificationList, totalCount, searchAfter, aggregations } = response

        commit('addToNotificationQueue', { notificationList })
        commit('setTotalCount', totalCount)
        commit('setSearchAfter', searchAfter)
        commit('setAggregations', aggregations)
      } catch (error) {
        commit('setError', error)
        console.error(error)
      }
    },
    async getBatchScheduledDate({ commit }) {
      try {
        const response = await notificationsApi.getBatchScheduledDate()
        commit('setBatchScheduledDate',
          response?.scheduledDate
        )
      } catch (error) {
        commit('setError', error)
        console.error(error)
      }
    },

    async fetchNotificationsQueue({ commit, state }, { isScroll, aggs }) {
      try {
        const response = await notificationsApi.getNotificationsList({
          filterParams: state.filterParams,
          search: state.searchParam,
          sortAttributes: state.sortAttributes,
          searchAfter: isScroll ? state.searchAfter : null,
          aggs
        })

        const { notificationList, totalCount, searchAfter, aggregations } = response

        commit('setTotalCount', totalCount)
        commit('setAggregations', aggregations)
        commit('setSearchAfter', searchAfter)
        commit('addToNotificationQueue', { notificationList, isScroll })
      } catch (error) {
        commit('setError', error)
        console.error(error)
      }
    },

    async updateErrorTabEnabled({ commit }) {
      try {
        const count = await notificationsApi.getNotificationErrorCount()
        commit('setErrorTabEnabled', count > 0)
      } catch (error) {
        console.error(error)
        commit('setErrorTabEnabled', false)
      }
    },

    async getNotificationData({ commit }, { notification }) {
      const response = await notificationsApi.getNotificationData({ notification })

      if (response.error) {
        throw JSON.parse(response.error.message)
      }

      return response?.data
    },

    async sendNotifications({ commit, state }, { notification }) {
      const response = await notificationsApi.sendNotifications({
        notification,
        filterParams: state.filterParams,
        search: state.searchParam
      })

      if (response.error) {
        throw JSON.parse(response.error.message)
      }

      return response?.data
    },

    async cancelSearchRequest({ commit, state }) {
      if (state.cancelTokenSource) {
        state.cancelTokenSource.cancel()
      }

      commit('setCancelTokenSource', null)
    },

    async deleteNotification({ commit, state }, { notification }) {
      const response = await notificationsApi.deleteNotification({
        notification,
        filterParams: state.filterParams,
        search: state.searchParam
      })

      if (response.error) {
        throw JSON.parse(response.error.message)
      }

      return response?.data
    },

    async resetState({ commit }) {
      commit('resetState')
    },

    async changeOutboxTab({ commit, state }, updatedFilter) {
      if (!state.filterParams) {
        commit('setFilterParams', updatedFilter)
      }

      commit('setFilterParams', {
        ...state.filterParams,
        ...updatedFilter
      })
      commit('setSearchAfter', undefined)
    },

    async changeNotificationAutomation({ commit, state }, { notification, sendType }) {
      const response = await notificationsApi.changeNotificationAutomation({
        notification,
        filterParams: state.filterParams,
        search: state.searchParam,
        sendType
      })

      if (response.error) {
        throw JSON.parse(response.error.message)
      }

      return response?.data
    },

    async getNotificationTemplateList({ commit }) {
      const response = await notificationsApi.getNotificationsTemplateList()

      if (response.error) {
        throw JSON.parse(response.error.message)
      }

      commit('setTemplateList', response.data.templateList)
    },

    async updateNotificationTemplateList({ commit }, template) {
      const response = await notificationsApi.updateTemplateList(template)

      if (response.error) {
        throw JSON.parse(response.error.message)
      }

      return response.data
    }

  },
  getters: {
    batchScheduledDate: (state) => state.batchScheduledDate,
    cancelTokenSource: (state) => state.cancelTokenSource,
    queue: (state) => state.queue,
    error: (state) => state.error,
    totalCount: (state) => state.totalCount,
    aggregations: (state) => state.aggregations,
    errorTabEnabled: (state) => state.errorTabEnabled,
    notificationTemplateList: (state) => state.notificationTemplateList
  }
}

export default notificationsQueue
