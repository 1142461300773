import Vue from 'vue'
import Vuex from 'vuex'

import authModule from './auth-module'
import plansModule from './plans-module'
import notificationsModule from './notifications-module'
import networkConfigModule from './network-config-module'
import customSitesModule from './custom-sites-module'
import notificationsQueueModule from '@/store/notifications-queue-module'
import testMembersCreationModule from '@/store/test-members-creation-module'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth: authModule,
    plans: plansModule,
    notifications: notificationsModule,
    notificationsQueue: notificationsQueueModule,
    networkConfig: networkConfigModule,
    customSites: customSitesModule,
    testMemberRequests: testMembersCreationModule
  }
})

export default store
