<template>
  <div class="modal no-padding" id="modal-warning">
    <div class="modal-content no-padding" :class="{ dismissible }" id="confirmationModal">
      <button v-if="dismissible" type="button" @click="closeBtnClick" aria-label="close modal" class="button--close">
        <img src="../assets/close.svg" alt="close icon" />
      </button>
      <template v-if="!isSuccess">
        <div class="title">
          <p class="modal-title" id="confirmationModalTitle">{{ title }}</p>
        </div>
        <p class="modal-text">
          <span class="modal-text bold-text" v-if="boldStartText">{{ boldStartText }} </span>
          <span v-html="text"></span>
        </p>

        <button class="btn-primary" @click="primaryBtnClick">
          {{ primaryBtnText }}
        </button>
        <button v-if="!cta" class="btn-primary-outline" @click="secondaryBtnClick">
          {{ secondaryBtnText }}
        </button>
      </template>
      <template v-else>
        <img src="../assets/success-modal.svg" alt="success icon" />
        <div class="title">
          <p class="modal-title center" id="confirmationModalTitle">Success</p>
        </div>
        <p class="modal-text|">{{ text }}</p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    text: String,
    boldStartText: {
      type: String,
      required: false
    },
    primaryBtnText: String,
    isSuccess: {
      type: Boolean,
      required: false
    },
    dismissible: {
      type: Boolean,
      required: false,
      default: false
    },
    secondaryBtnText: {
      type: String,
      required: false
    },
    cta: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    primaryBtnClick () {
      this.$emit('primaryBtnClick')
    },
    secondaryBtnClick () {
      this.$emit('secondaryBtnClick')
    },
    closeBtnClick () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal > .modal-content {
  position: relative;

  .button--close {
    background-color: #F6F7FA;
    border-radius: 4px;
    width: 3rem;
    height: 3rem;
    border: none;
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    z-index: 2;
    cursor: pointer;
  }

  .center {
    text-align: center;
  }

  div.title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0;

    > button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 3.4rem;
      height: 3rem;
      background-color: #F6F7FA;
      border: none;
      border-radius: 0.25rem;
      cursor: pointer;
      color: #7a7e81;
    }
  }

  button.btn-primary, button.btn-primary-outline {
    width: 100%;
  }

  &.dismissible {
    p.modal-title {
      width: 85%;
    }
  }

  .bold-text {
    font-weight: 700;
  }
}
</style>
