<template>
  <div id="app">
    <timeout v-if="getLoggedIn"/>
    <LayoutNav/>
    <div class="container-fluid">
      <router-view/>
    </div>
  </div>
</template>

<script>
import LayoutNav from './layout/layout-nav'
import timeout from './components/timeout.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'app',
  components: {
    LayoutNav,
    timeout
  },
  computed: {
    ...mapGetters('auth', [
      'getLoggedIn'
    ])
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

.open-sans {
  font-family: 'Open Sans', sans-serif;
}

.vue-portal-target {
  position: static;
  inset: 0;
  z-index: 10000;
}
</style>
