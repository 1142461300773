import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const networkModule = {
  namespaced: true,
  state: () => ({
    networkData: null
  }),
  mutations: {
    setNetworkData (state, networkData) {
      state.networkData = networkData
    }
  },
  actions: {
    setNetworkData ({ commit, getters, state }, networkData) {
      commit('setNetworkData', networkData)
    }
  },
  modules: {
  },
  getters: {
    getNetworkData: state => state.networkData
  }
}

export default networkModule
