import Vue from 'vue'
import Vuex from 'vuex'
import authApi from '@/api/authApi'
import { ToastProgrammatic as Toast } from 'buefy'

import client from '@/api/networkConfigApi'

Vue.use(Vuex)

const authModule = {
  namespaced: true,
  state: () => ({
    loggedIn: false,
    accessToken: null,
    refreshToken: null,
    userId: null,
    username: null,
    whitelisted: false
  }),
  mutations: {
    setLoggedIn (state, loggedIn) {
      state.loggedIn = loggedIn
    },
    setAccessToken (state, accessToken) {
      state.accessToken = accessToken
      client.setAuthHeader(accessToken)
    },
    setRefreshToken (state, refreshToken) {
      state.refreshToken = refreshToken
    },
    setUserId (state, userId) {
      state.userId = userId
    },
    setUsername (state, username) {
      state.username = username
    },
    setWhitelisted (state, whitelisted) {
      state.whitelisted = whitelisted
    }
  },
  actions: {
    async authenticate ({ commit, getters, state }, { username, password }) {
      return authApi.login(username, password)
        .then(async (res) => {
          if (res.data) {
            const accessToken = res.data.authToken
            const refreshToken = res.data.refreshToken
            const whitelisted = res.data.whitelisted

            if (accessToken && refreshToken) {
              window.localStorage.setItem('accessToken', accessToken)
              window.localStorage.setItem('refreshToken', refreshToken)
              window.localStorage.setItem('whitelisted', whitelisted)
              const userInfo = authApi.getUserInfo(accessToken)
              commit('setLoggedIn', true)
              commit('setAccessToken', accessToken)
              commit('setRefreshToken', refreshToken)
              commit('setUserId', userInfo.id)
              commit('setUsername', userInfo.username)
              commit('setWhitelisted', whitelisted)

              return accessToken
            }
          }
        })
        .catch(err => {
          throw new Error(err)
        })
    },
    async logout ({ commit, getters, state }) {
      return authApi.logout()
        .then(() => {
          window.localStorage.removeItem('accessToken')
          window.localStorage.removeItem('refreshToken')
          window.localStorage.removeItem('whitelisted')
          commit('setLoggedIn', false)
          commit('setAccessToken', null)
          commit('setRefreshToken', null)
          commit('setUserId', null)
          commit('setUsername', null)
          commit('setWhitelisted', false)

          Toast.open({
            message: 'Logged out successfully!',
            type: 'is-success',
            duration: 5000,
            position: 'is-bottom'
          })

          return true
        })
        .catch(() => {
          Toast.open({
            message: 'Error logging out',
            type: 'is-danger',
            duration: 5000,
            position: 'is-bottom'
          })
          return false
        })
    },
    async refresh ({ commit, getters, state }) {
      return authApi.refresh()
        .then((res) => {
          if (res.data) {
            const accessToken = res.data.authToken
            const refreshToken = res.data.refreshToken

            if (accessToken && refreshToken) {
              window.localStorage.setItem('accessToken', accessToken)
              window.localStorage.setItem('refreshToken', refreshToken)
              const userInfo = authApi.getUserInfo(accessToken)
              commit('setLoggedIn', true)
              commit('setAccessToken', accessToken)
              commit('setRefreshToken', refreshToken)
              commit('setUserId', userInfo.id)
              commit('setUsername', userInfo.username)
              return true
            }
          }
        })
        .catch(err => {
          console.error('Error authenticating', err)
          return err
        })
    },
    async sendAccessCode ({ commit, getters, state }, { email }) {
      return authApi.sendAccessCode(email)
    },
    async verifyAccount ({ commit, getters, state }, { email, newPassword, accessCode }) {
      return authApi.verifyAccount(email, newPassword, accessCode)
    },
    async forgotPassword ({ commit, getters, state }, { username }) {
      return authApi.forgotPassword(username)
    },
    async confirmPassword ({ commit, getters, state }, { username, confirmationCode, password }) {
      return authApi.confirmPassword(username, confirmationCode, password)
    },
    async isAuthenticated ({ commit, getters, state, dispatch }) {
      if (state.loggedIn === false) return false
      else if (!state.accessToken) return false
      // If "logged in" but the access token has expired, attempt to refresh the session
      else if (authApi.isAccessExpired(state.accessToken) === true) {
        const refreshRes = await dispatch('refresh')
        if (refreshRes === true) return true
        else {
          window.localStorage.removeItem('accessToken')
          window.localStorage.removeItem('refreshToken')
          window.localStorage.removeItem('whitelisted')
          commit('setLoggedIn', false)
          commit('setAccessToken', null)
          commit('setRefreshToken', null)
          commit('setUserId', null)
          commit('setUsername', null)
          commit('setWhitelisted', false)
          return false
        }
      }
      return true
    },
    async loadSession ({ commit, getters, state, dispatch }) {
      const accessToken = window.localStorage.getItem('accessToken')
      const refreshToken = window.localStorage.getItem('refreshToken')
      const whitelisted = window.localStorage.getItem('whitelisted')

      if (accessToken && refreshToken) {
        if (authApi.isAccessExpired(accessToken) === false) {
          const userInfo = authApi.getUserInfo(accessToken)
          commit('setLoggedIn', true)
          commit('setAccessToken', accessToken)
          commit('setRefreshToken', refreshToken)
          commit('setUserId', userInfo.id)
          commit('setUsername', userInfo.username)
          commit('setWhitelisted', whitelisted)

          return true
        } else {
          const refreshRes = await dispatch('refresh')
          if (refreshRes === true) return true
        }
      }

      commit('setLoggedIn', false)
      commit('setAccessToken', null)
      commit('setRefreshToken', null)
      return false
    },
    async setLoggedIn ({ commit, getters, state }, loggedIn) {
      commit('setLoggedIn', loggedIn)
    },
    async setAccessToken ({ commit, getters, state }, accessToken) {
      commit('setAccessToken', accessToken)
    },
    async setRefreshToken ({ commit, getters, state }, refreshToken) {
      commit('setRefreshToken', refreshToken)
    },
    async setUserId ({ commit, getters, state }, userId) {
      commit('setUserId', userId)
    },
    async setUsername ({ commit, getters, state }, username) {
      commit('setUsername', username)
    },
    async setWhitelisted ({ commit, getters, state }, whitelisted) {
      commit('setWhitelisted', whitelisted)
    }
  },
  modules: {
  },
  getters: {
    getLoggedIn: state => state.loggedIn,
    getAccessToken: state => state.accessToken,
    getRefreshToken: state => state.refreshToken,
    getUserId: state => state.userId,
    getUsername: state => state.username,
    getWhitelisted: state => state.whitelisted
  }
}

export default authModule
