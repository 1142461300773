/* eslint-disable */
import planApi from '@/api/planApi'
import _ from 'lodash'
const state = {
  defaultNotificationConfig: null,
  hasNotificationConfigChange: false,
  showWarningModal: false,
  message: '',
  error: false,
}
const getters = {
  getDefaultNotificationConfig: (state) => state.defaultNotificationConfig,
  getHasNotificationConfigChange: (state) => state.hasNotificationConfigChange,
  getShowWarningModal: (state) => state.showWarningModal
}

const actions = {

  async fetchDefaultNotificationConfig({ commit, state }) {
    try {
      //TODO once this is finalized move this to dynamodb table and fetch default notificationConfig

      if (!sessionStorage.getItem('defaultNotificationConfig')) {
        const response = await planApi.getDefaultNotificationConfig();
        //schema is very important since the UI is rendered dynamically based on these value
        let data = response.data;

        delete data.clientId;
        delete data['productType#planId#configType']
        sessionStorage.setItem('defaultNotificationConfig', JSON.stringify(data))
        commit('setSefaultNotificationConfig', data)
      }
    } catch (error) {
      console.log(error)
    }
  },

  async updateNotificationConfigByClientIdandPlanId({ commit, state }, payload) {
    const response = await planApi.updateNotificationConfigByClientIdandPlanId(payload).catch(err => {
      commit('setError', err.message)
    })
    // console.log(response.data)
    return response.data

  },
  updateHasNotificationConfigChange({ commit }, payload) {
    commit('setHasNotificationConfigChange', payload)
  },
  updateShowWarningModal({ commit }, payload) {
    commit('setShowWarningModal', payload)
  }
}

const mutations = {
  setError: (state, payload) => (state.error = payload),
  setSefaultNotificationConfig: (state, defaultNotificationConfig) => (state.defaultNotificationConfig = defaultNotificationConfig),
  setHasNotificationConfigChange: (state, payload) => (state.hasNotificationConfigChange = payload),
  setShowWarningModal: (state, payload) => (state.showWarningModal = payload),
  updateMessage(state, payload) {
    state.message = payload;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
