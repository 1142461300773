import store from '@/store/store'
import { Buffer } from 'buffer'
const axios = require('axios').default

const sucureInsatance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

sucureInsatance.interceptors.request.use(
  async config => {
    const isAuthenticated = await store.dispatch('auth/isAuthenticated')

    if (isAuthenticated) {
      config.headers.Authorization = `Bearer ${store.getters['auth/getAccessToken']}`
    }
    return config
  },
  error => {
    // TODO handle refreshing of token
    return Promise.reject(error)
  }
)

const getAllHealthEdgeConfig = async (lastEvaluatedKey) => {
  const url = '/healthedge/getAllHealthEdgeConfig'
  const response = await sucureInsatance.get(url, {
    params: {
      lastEvaluatedKey
    }
  })
  return response.data
}

const getHealthEdgePlansInBatch = async (payload) => {
  const url = '/healthedge/plans/getPlansInBatch'
  const response = await sucureInsatance.post(url, payload)
  return { data: response.data }
}

const getHealthEdgePlan = async (planId, clientId) => {
  const url = '/healthedge/plans/getMedicalPlan'
  return await sucureInsatance.get(url, {
    params: {
      planId: planId,
      clientId
    }
  })
}

const updateHealthEdgePlan = async (planData) => {
  if ((await store.dispatch('auth/isAuthenticated')) === false) return

  return axios.put(
    `${process.env.VUE_APP_API_URL}/healthedge/plans/updateMedicalPlan`,
    planData,
    {
      headers: {
        Authorization: `Bearer ${store.getters['auth/getAccessToken']}`
      }
    }
  )
}

const getAllConfig = async (lastEvaluatedKey) => {
  let url = '/clients/getAllConfig'

  if (lastEvaluatedKey) {
    console.log('sending', lastEvaluatedKey)
    const encodedValue = Buffer.from(JSON.stringify(lastEvaluatedKey)).toString('base64')
    url = url + `?lastEvaluatedKey=${encodeURIComponent(encodedValue)}`
  }

  const response = await sucureInsatance.get(url)
  return response.data
}

const getPlansInBatch = async (payload) => {
  const response = await sucureInsatance.post('/plans/getPlansInBatch', payload)
  return { data: response.data }
}

const getPlans = async () => {
  if ((await store.dispatch('auth/isAuthenticated')) === false) return

  return axios.get(`${process.env.VUE_APP_API_URL}/plans/getAllMedicalPlans`, {
    headers: {
      Authorization: `Bearer ${store.getters['auth/getAccessToken']}`
    }
  })
}

const getPlan = async (planId, clientId) => {
  if ((await store.dispatch('auth/isAuthenticated')) === false) return

  const pidParam = `planId=${planId}`
  const cidParam = `clientId=${clientId}`

  return await axios.get(
    `${process.env.VUE_APP_API_URL}/plans/getMedicalPlan?${pidParam}&${cidParam}`,
    {
      headers: {
        Authorization: `Bearer ${store.getters['auth/getAccessToken']}`
      }
    }
  )
}

const getDefaultNotificationConfig = async () => {
  if ((await store.dispatch('auth/isAuthenticated')) === false) return

  return axios.get(
    `${process.env.VUE_APP_API_URL}/plans/getDefaultNotificationConfig`,
    {
      headers: {
        Authorization: `Bearer ${store.getters['auth/getAccessToken']}`
      }
    }
  )
}

const updatePlan = async (planData) => {
  if ((await store.dispatch('auth/isAuthenticated')) === false) return

  return axios.put(
    `${process.env.VUE_APP_API_URL}/plans/updateMedicalPlan`,
    planData,
    {
      headers: {
        Authorization: `Bearer ${store.getters['auth/getAccessToken']}`
      }
    }
  )
}

const updateNotificationConfigByClientIdandPlanId = async (body) => {
  if ((await store.dispatch('auth/isAuthenticated')) === false) return

  return axios.put(
    `${process.env.VUE_APP_API_URL}/plans/updateNotificationConfigByClientIdandPlanId`,
    body,
    {
      headers: {
        Authorization: `Bearer ${store.getters['auth/getAccessToken']}`
      }
    }
  )
}

const updatePlanNetworkAffiliationForClient = async (body) => {
  if ((await store.dispatch('auth/isAuthenticated')) === false) return

  return axios.put(
    `${process.env.VUE_APP_API_URL}/plans/updatePlanNetworkAffiliationForClient`,
    body,
    {
      headers: {
        Authorization: `Bearer ${store.getters['auth/getAccessToken']}`
      }
    }
  )
}

const syncHealthEdgePlans = async () => {
  return sucureInsatance.post(`${process.env.VUE_APP_API_URL}/healthedge/sync`, {})
}

const syncPlans = async () => {
  if ((await store.dispatch('auth/isAuthenticated')) === false) return

  return await axios.post(
    `${process.env.VUE_APP_API_URL}/javelina/sync`,
    {},
    {
      headers: {
        Authorization: `Bearer ${store.getters['auth/getAccessToken']}`
      }
    }
  )
}

const getSettings = () => {
  return axios.get(`${process.env.VUE_APP_API_URL}/settings/getAllSettings`, {
    headers: {
      Authorization: `Bearer ${store.getters['auth/getAccessToken']}`
    }
  })
}

export default {
  getAllHealthEdgeConfig,
  getHealthEdgePlansInBatch,
  getHealthEdgePlan,
  updateHealthEdgePlan,
  getAllConfig,
  getPlansInBatch,
  getPlans,
  getPlan,
  updatePlan,
  syncHealthEdgePlans,
  syncPlans,
  getSettings,
  updateNotificationConfigByClientIdandPlanId,
  getDefaultNotificationConfig,
  updatePlanNetworkAffiliationForClient
}
