import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const coverageDetailsModule = {
  namespaced: true,
  state: () => ({
    coverageDetailsData: null
  }),
  mutations: {
    setCoverageDetailsData (state, coverageDetailsData) {
      state.coverageDetailsData = coverageDetailsData
    }
  },
  actions: {
    setCoverageDetailsData ({ commit, getters, state }, coverageDetailsData) {
      commit('setCoverageDetailsData', coverageDetailsData)
    }
  },
  modules: {
  },
  getters: {
    getCoverageDetailsData: state => state.coverageDetailsData
  }
}

export default coverageDetailsModule
