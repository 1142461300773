<template>
  <div id="nav">
    <b-navbar class="container-fluid" wrapper-class="container" shadow>
      <template v-slot:brand class="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img :src="require('../assets/Story-Icon-Hero.svg')" alt="Centivo" />
          <p v-if="isDesktop">Plan Management</p>
        </b-navbar-item>
      </template>
      <template v-slot:start v-if="showNavLinks && !flag_suppressNetworkConfig">
        <b-navbar-item>
          <router-link to="/plans">Home</router-link>
        </b-navbar-item>
        <b-navbar-item>
          <router-link to="/networks">Network configurations</router-link>
        </b-navbar-item>
        <b-navbar-item v-if="activeTab('ENROLLMENT')">
          <router-link to="/pre-enrollment-custom">Pre-enrollment custom</router-link>
        </b-navbar-item>
        <b-navbar-item v-if="activeTab('OUTBOX') && !flag_suppressNotificationsQueue">
          <router-link to="/notifications-queue">Notifications Outbox</router-link>
        </b-navbar-item>
        <b-navbar-item v-if="activeTab('TESTMEMBERS')">
          <router-link to="/test-members-creation">Test Members Creation</router-link>
        </b-navbar-item>
      </template>
      <template v-slot:end>
        <b-navbar-item v-if="showNavLinks" tag="router-link" :to="{ path: '/logout' }">
          Logout
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
// import { Fragment } from 'vue-fragment'
import { mapState } from 'vuex'

export default {
  name: 'layout-nav',
  // components: { Fragment },
  data () {
    return {
      isDesktop: window.innerWidth >= 1024,
      flag_suppressNetworkConfig: process.env.VUE_APP_SUPPRESS_NETWORK_CONFIGURATIONS === 'true',
      flag_suppressNotificationsQueue: process.env.VUE_APP_SUPPRESS_NOTIFICATIONS_QUEUE === 'true'
    }
  },
  computed: mapState({
    showNavLinks: state => {
      return state.auth.loggedIn
    },
    whitelistPermissions: state => {
      return state.auth.whitelisted?.toUpperCase()
    }
  }),
  mounted () {
    window.addEventListener('resize', this.onResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      this.isDesktop = window.innerWidth >= 1024
    },
    activeTab (tab) {
      // Do not show Test Members tab in prod
      if (window.location.hostname.endsWith('.com') && tab === 'TESTMEMBERS') {
        return false
      }

      return this.whitelistPermissions === 'ALL' || this.whitelistPermissions?.includes(tab)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-item {
  align-items: flex-start;
  font-size: large;
  font-weight: 600;
}

.navbar-start .navbar-item {
  padding-bottom: 0;

  &:not(:last-child) {
    margin-right: 2rem;
  }
}

.navbar-start .navbar-item:not(:last-child) {
  margin-right: 2rem;
}

.navbar-start .navbar-item,
.navbar-end .navbar-item {
  padding-top: 2.625rem;
  height: 100%;

  a {
    height: 100%;
  }

  &:hover {
    background-color: transparent;
  }
}

.router-link-active:not(.navbar-brand .navbar-item) {
  border-bottom: 3px solid #000000;
}

@media screen and (min-width: 1024px) {
  .navbar-brand .navbar-item {
    display: flex;
    align-items: center;
    padding-top: calc(42px - 23px);
    padding-bottom: 0;
    margin-right: 60px;

    p {
      color: #00A69E;
      font-size: x-large;
      margin-left: 12px;
    }

    img {
      max-height: 3.125rem;
    }
  }
}
</style>
