/**
 * change a full date into a short date, showing the month, day and year
 * @params fullDate (yyyy-mm-ddT00:00:00.000-00:00)
 * @returns short date (m-dd-yyyy)
 */
export const fullDateToDate = (fullDate: string) => {
  const date = new Date(fullDate)

  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

/**
 * it uses the getTimezoneOffset method which returns the reverse offset from UTC in minutes, divides it
 * by -60 to get the real offset in hours, then gets the absolute hour value rounding it using math floor,
 * finally it gets the reminder of the division between the timezone offset absolute value and 60 to get
 * the minutes
 * @returns formatted timezone offset in hours (e.g -03:00)
 */
export const getTimezoneOffsetInHours = () => {
  const timezoneOffset = new Date().getTimezoneOffset()
  const timezoneOffsetInHours = (timezoneOffset / -60)
  const absoluteTimezoneOffsetInHours = Math.floor(Math.abs(timezoneOffsetInHours))
  const reminderOfTimezoneOffsetDivision = Math.abs(timezoneOffset % (60))

  return `${timezoneOffsetInHours > 0 ? '+' : '-'}${Math.abs(absoluteTimezoneOffsetInHours).toString().length === 1 ? '0' : ''}${Math.abs(absoluteTimezoneOffsetInHours)}:${reminderOfTimezoneOffsetDivision > 0 ? reminderOfTimezoneOffsetDivision : '00'}`
}
