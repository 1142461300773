interface Notification {
    recipientId: string
    sk?: string
    recipientFamilyId: string
    recipientEligibilityType: string
    recipientFirstName: string
    recipientLastName: string
    emailAddress: string
    client: string
    plan: string
    sent: string
    type: string
    event: string
    NotificationsTemplateName: string
    status: string
    sendType: string
    recipientPcpNpi?: string
    networkName?: string
    scheduleId?: string
    scheduledTimestamp?: string
    createdTimestamp?: number
    created?: string
    modified?: string
    'recipientType#notificationType#notificationEvent#notificationId'?: string
}

export const handleNotificationsRecords = (notificationList: Notification) => {
  if (notificationList.sk) {
    notificationList['recipientType#notificationType#notificationEvent#notificationId'] = notificationList.sk
  }

  return notificationList
}

/**
 * Removes null or undefined fields from an object
 * @param openSearchParams
 * @returns
 */
export const cleanNotificationOpenSearchParams = (openSearchParams: Object) => {
  return Object.fromEntries(Object.entries(openSearchParams).filter(([_, v]) => v != null))
}

export const convertToCSV = (arr: Array<any>) => {
  const array = [Object.keys(arr[0])].concat(arr)

  return array.map(it => {
    return Object.values(it).toString()
  }).join('\n')
}

export const sanitizeObj = (obj: any, requiredFields: Array<any>) => {
  const newObj: Object = {}

  for (const field of requiredFields) {
    newObj[field] = !obj[field] ? 'N/A' : obj[field]
  }

  return newObj
}
