import store from '@/store/store'
import axios from 'axios'
import axiosRetry from 'axios-retry'

const axiosRetryInstance = axios.create({ baseURL: process.env.VUE_APP_API_URL })

axiosRetry(axiosRetryInstance, {
  retries: 3,
  retryCondition: (response) => response.statusCode !== 200
})

const getTestMemberRequestsList = async () => {
  const response = await axios.get(`${process.env.VUE_APP_API_URL}/test-members/getTestMemberRequests`, {
    headers: {
      Authorization: `Bearer ${store.getters['auth/getAccessToken']}`
    }
  })

  return response.data;
}

const createTestMemberRequest = async (body) => {
  const response = await axios.post(`${process.env.VUE_APP_API_URL}/test-members/createTestMemberRequest`,
    body,
    {
      headers: {
        Authorization: `Bearer ${store.getters['auth/getAccessToken']}`
      }
    }
  )

  return response.data;
}

const updateTestMemberRequest = async (body) => {
  const response = await axios.put(`${process.env.VUE_APP_API_URL}/test-members/updateTestMemberRequest`,
    body,
    {
      headers: {
        Authorization: `Bearer ${store.getters['auth/getAccessToken']}`
      }
    }
  )

  return response.data;
}

export default {
  getTestMemberRequestsList,
  createTestMemberRequest,
  updateTestMemberRequest
}
