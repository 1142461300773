import { isEmpty, isEqual } from 'lodash'

/** This WILL break any references. BE CAREFUL */
export const observerToObject = (observer: object): object => {
  if (!observer) return {}
  return JSON.parse(JSON.stringify(observer))
}

export const obsIsEqual = (observer1: object, observer2: object) => {
  return isEqual(observerToObject(observer1), observerToObject(observer2))
}

export const obsIsEmpty = (observer: object) => {
  return isEmpty(observerToObject(observer))
}
