import { v4 as uuidv4 } from 'uuid'
import { ClientApi } from '@/types/client-api'

export const addClientConfig = (): ClientApi.Network => {
  return { name: undefined, directoryType: undefined, preEnrollment: undefined, networkSeries: [], isValid: false, includePreEnrollmentSite: undefined, uuid: uuidv4() }
}

export const createNewConfigArray = (): ClientApi.Network[] => {
  return [addClientConfig()]
}
