import Vue from 'vue'
import Vuex from 'vuex'
import testMembersCreationApi from '@/api/testMembersCreationApi'

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    testMemberRequestsList: []
  }
}

const testMemberRequests = {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    async setTestMemberRequests (state, testMemberRequests) {
      state.testMemberRequestsList = testMemberRequests
    }
  },
  actions: {
    async getTestMemberRequests({ commit, state }) {
      const testMemberRequests = await testMembersCreationApi.getTestMemberRequestsList()
      commit('setTestMemberRequests', testMemberRequests)
    },
    async getTestMemberRequestByUid({ commit, state }, { itemUid }) {
      const filteredRequest = state.testMemberRequestsList.filter((testMemberRequest) => testMemberRequest.generatedUid === itemUid)
      return filteredRequest[0]
    }
  },
  getters: {
    getTestMemberRequestsList: (state) => {
      return state.testMemberRequestsList
    }
  }
}

export default testMemberRequests
