import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const memberCostsModule = {
  namespaced: true,
  state: () => ({
    defaultMemberCostList: [
      { category: 'Deductible', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Out-of-pocket Max', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'PCP Visits', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Other PCP Visits', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Preventive Care', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Specialists', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Labs', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Basic Imaging', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Diagnostic Testing', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Advanced Imaging', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Inpatient Hospitalization', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Outpatient Facility', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Urgent Care', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'ER', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: '', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', last: true }
    ],
    memberCostData: [
      { category: 'Deductible', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Out-of-pocket Max', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'PCP Visits', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Other PCP Visits', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Preventive Care', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Specialists', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Labs', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Basic Imaging', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Diagnostic Testing', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Advanced Imaging', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Inpatient Hospitalization', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Outpatient Facility', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'Urgent Care', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: 'ER', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', static: true },
      { category: '', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', last: true }
    ],
    rxCoverageData: [
      { category: '', userInput1: '', userInput2: '', last: true }
    ],
    rxCoverageSpecialtyList: [
      { category: '', userInput1: '', userInput2: '', last: true }
    ],
    checkedMemberCostData: [],
    checkedRxCoverageData: [],
    checkedRxCoverageSpecialtyData: [],
    customMemberCostHeaders: { tier1: '', tier2: '' },
    customRxHeaders: null,
    customRxSpecialtyHeaders: null
  }),
  mutations: {
    setMemberCostData (state, memberCostData) {
      state.memberCostData = memberCostData
    },

    setRxCoverageData (state, rxCoverageData) {
      state.rxCoverageData = rxCoverageData
    },

    setRxCoverageSpecialtyData (state, rxCoverageSpecialtyData) {
      state.rxCoverageSpecialtyList = rxCoverageSpecialtyData
    },

    setCheckedMemberCostData (state, checkedMemberCostData) {
      state.checkedMemberCostData = checkedMemberCostData
    },

    setCheckedRxCoverageData (state, checkedRxCoverageData) {
      state.checkedRxCoverageData = checkedRxCoverageData
    },

    setCheckedRxCoverageSpecialtyData (state, checkedRxCoverageSpecialtyData) {
      state.checkedRxCoverageSpecialtyData = checkedRxCoverageSpecialtyData
    },

    setCustomMemberCostHeaders (state, customMemberCostHeaders) {
      state.customMemberCostHeaders = customMemberCostHeaders
    },

    setCustomRxHeaders (state, customRxHeaders) {
      state.customRxHeaders = customRxHeaders
    },

    setCustomRxSpecialtyHeaders (state, customRxHeaders) {
      state.customRxSpecialtyHeaders = customRxHeaders
    }
  },
  actions: {
    async getMemberCostData ({ commit, getters, state }) {
      return new Promise((resolve, reject) => {
        commit('setMemberCostData', state.memberCostData)
        resolve(state.memberCostData)
      })
    },

    async getRXCoverageData ({ commit, getters, state }) {
      return new Promise((resolve, reject) => {
        commit('setRxCoverageData', state.rxCoverageData)
        resolve(state.rxCoverageData)
      })
    },

    async getCustomMemberCostHeaders ({ commit, getters, state }) {
      return new Promise((resolve, reject) => {
        commit('setCustomMemberCostHeaders', state.customMemberCostHeaders)
        resolve(state.customMemberCostHeaders)
      })
    },

    async getCustomRxHeaders ({ commit, getters, state }) {
      return new Promise((resolve, reject) => {
        commit('setCustomRxHeaders', state.customRxHeaders)
        resolve(state.customRxHeaders)
      })
    },

    async getCustomRxSpecialtyHeaders ({ commit, getters, state }) {
      return new Promise((resolve, reject) => {
        commit('setCustomRxHeaders', state.customRxSpecialtyHeaders)
        resolve(state.customRxSpecialtyHeaders)
      })
    },

    async getRxCoverageSpecialtyData ({ commit, getters, state }) {
      return new Promise((resolve, reject) => {
        commit('setRxCoverageSpecialtyData', state.rxCoverageSpecialtyList)
        resolve(state.rxCoverageSpecialtyList)
      })
    },

    async setMemberCostData ({ commit, getters, state }, memberCostData) {
      commit('setMemberCostData', memberCostData)
    },

    async setRxCoverageData ({ commit, getters, state }, rxCoverageData) {
      commit('setRxCoverageData', rxCoverageData)
    },

    async setRxCoverageSpecialtyData ({ commit, getters, state }, rxCoverageSpecialtyData) {
      commit('setRxCoverageSpecialtyData', rxCoverageSpecialtyData)
    },

    async setCheckedMemberCostData ({ commit, getters, state }, checkedMemberCostData) {
      commit('setCheckedMemberCostData', checkedMemberCostData)
    },

    async setCheckedRxCoverageData ({ commit, getters, state }, checkedRxCoverageData) {
      commit('setCheckedRxCoverageData', checkedRxCoverageData)
    },

    async setCheckedRxCoverageSpecialtyData ({ commit, getters, state }, checkedRxCoverageSpecialtyData) {
      commit('setCheckedRxCoverageSpecialtyData', checkedRxCoverageSpecialtyData)
    },

    async resetMemberCostPageData ({ commit, getters, state }) {
      state.rxCoverageData = [{ category: '', userInput1: '', userInput2: '', last: true }]
      state.rxCoverageSpecialtyList = [{ category: '', userInput1: '', userInput2: '', last: true }]
      state.checkedMemberCostData = []
      state.checkedRxCoverageData = []
      state.checkedRxCoverageSpecialtyData = []
      state.memberCostData = state.defaultMemberCostList.map(costs => ({ ...costs }))
      state.customMemberCostHeaders = {
        tier1: '',
        tier2: ''
      }
      state.customRxHeaders = {
        customHeader1: '',
        customHeader2: ''
      }
      state.customRxSpecialtyHeaders = {
        customHeader1: '',
        customHeader2: ''
      }
    },

    async setCustomMemberCostHeaders ({ commit, getters, state }, customMemberCostHeaders) {
      commit('setCustomMemberCostHeaders', customMemberCostHeaders)
    },

    async setCustomRxHeaders ({ commit, getters, state }, customRxHeaders) {
      commit('setCustomRxHeaders', customRxHeaders)
    },

    async setCustomRxSpecialtyHeaders ({ commit, getters, state }, customRxHeaders) {
      commit('setCustomRxSpecialtyHeaders', customRxHeaders)
    }
  },
  modules: {
  },
  getters: {
    getMemberCostData: state => state.memberCostData,
    getRxCoverageData: state => state.rxCoverageData,
    getCheckedMemberCostData: state => state.checkedMemberCostData,
    getCheckedRxCoverageData: state => state.checkedRxCoverageData,
    getCustomRxHeaders: state => state.customRxHeaders,
    getRxCoverageSpecialtyData: state => state.rxCoverageSpecialtyList,
    getCheckedRxCoverageSpecialtyData: state => state.checkedRxCoverageSpecialtyData,
    getCustomRxSpecialtyHeaders: state => state.customRxSpecialtyHeaders,
    getDefaultMemberCostList: state => state.defaultMemberCostList,
    getCustomMemberCostHeaders: state => state.customMemberCostHeaders
  }
}

export default memberCostsModule
