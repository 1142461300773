import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const planDesignModule = {
  namespaced: true,
  state: () => ({
    planDesignData: null
  }),
  mutations: {
    setPlanDesignData (state, planDesignData) {
      state.planDesignData = planDesignData
    }
  },
  actions: {
    setPlanDesignData ({ commit, getters, state }, planDesignData) {
      commit('setPlanDesignData', planDesignData)
    }
  },
  modules: {
  },
  getters: {
    getPlanDesignData: state => state.planDesignData
  }
}

export default planDesignModule
