import Vue from 'vue'
import Vuex from 'vuex'

import client from '@/api/networkConfigApi'
import { isSuccessResponse } from '@/helpers/is-success-response'
import { ClientApi } from '@/types/client-api'
import { Client } from '@/types/client-config'

Vue.use(Vuex)

type CustomSitesModuleState = {
  customSites: ClientApi.Client[]
  customSitesGrid: Client.ConfigGrid[]
  showCustomSitesAlertModal: Boolean | String,
  customSitesHasChanged: Boolean
}

const customSitesModule: {
  namespaced: boolean,
  state: () => CustomSitesModuleState,
  mutations: {
    setCustomSites: (state: CustomSitesModuleState, customSites: ClientApi.Client[]) => void
    setCustomSitesGrid: (state: CustomSitesModuleState, customSitesGrid: Client.ConfigGrid[]) => void
    setShowCustomSitesAlertModal: (state: CustomSitesModuleState, showCustomSitesAlertModal: Boolean | String) => void
    setCustomSitesHasChanged: (state: CustomSitesModuleState, customSitesHasChanged: Boolean) => void
  }
  actions: {
    getCustomSites: (params: { commit: any }) => void
    setShowCustomSitesAlertModal: (params: { commit: any }, showCustomSitesAlertModal: Boolean | String) => void
    setCustomSitesHasChanged: (params: { commit: any }, customSitesHasChanged: Boolean) => void
  }
  getters: {
    getCustomSites: (state: CustomSitesModuleState) => ClientApi.Client[],
    getCustomSitesGrid: (state: CustomSitesModuleState) => Client.ConfigGrid[]
    getShowCustomSitesAlertModal: (state: CustomSitesModuleState) => Boolean | String,
    getCustomSitesHasChanged: (state: CustomSitesModuleState) => Boolean
  }
} = {
  namespaced: true,
  state: () => ({
    customSites: [],
    customSitesGrid: [],
    showCustomSitesAlertModal: false,
    customSitesHasChanged: false
  }),
  mutations: {
    setCustomSites (state, customSites) {
      state.customSites = customSites
    },
    setCustomSitesGrid (state, customSitesGrid) {
      state.customSitesGrid = customSitesGrid
    },
    setShowCustomSitesAlertModal (state, showCustomSitesAlertModal) {
      state.showCustomSitesAlertModal = showCustomSitesAlertModal
    },
    setCustomSitesHasChanged (state, customSitesHasChanged) {
      state.customSitesHasChanged = customSitesHasChanged
    }
  },
  actions: {
    async getCustomSites ({ commit }) {
      const response = await client.getCustomClientConfig(true)

      if (isSuccessResponse(response)) {
        const customSites = response.data

        commit('setCustomSites', customSites)

        const parsedCustomSitesList = customSites.map(config => {
          const parsedData = {
            clientName: config.name,
            clientId: config.clientId ?? '',
            oeUrlName: config.preEnrollment?.siteURL ?? 'N/A',
            status: 'Not Started'
          }

          if (config.preEnrollment?.hasCustomization) {
            parsedData.status = config.preEnrollment?.publish ? 'Published' : 'Unpublished'
          }

          return parsedData
        }).sort((a, b) => a.clientName.localeCompare(b.clientName))

        commit('setCustomSitesGrid', parsedCustomSitesList)
      }
    },
    setShowCustomSitesAlertModal ({ commit }, showCustomSitesAlertModal) {
      commit('setShowCustomSitesAlertModal', showCustomSitesAlertModal)
    },
    setCustomSitesHasChanged ({ commit }, customSitesHasChanged) {
      commit('setCustomSitesHasChanged', customSitesHasChanged)
    }
  },
  getters: {
    getCustomSites: state => state.customSites,
    getCustomSitesGrid: state => state.customSitesGrid,
    getShowCustomSitesAlertModal: state => state.showCustomSitesAlertModal,
    getCustomSitesHasChanged: state => state.customSitesHasChanged
  }
}

export default customSitesModule
