import { set } from 'lodash'

export const handleFormSubmit = (e: Event) => {
  e.preventDefault()

  const formData = new FormData(e.target as HTMLFormElement)
  const data = Object.fromEntries(formData)

  return Object.keys(data).reduce((acc, curr) => {
    const parsedData = { ...acc }
    set(parsedData, curr, data[curr])

    return parsedData
  }, {})
}
