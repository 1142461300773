import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'

Vue.use(VueRouter)

const authGuard = async (to, from, next) => {
  if ((await store.dispatch('auth/isAuthenticated')) === true) next()
  else if ((await store.dispatch('auth/loadSession')) === true) next()
  else next('/login')
}

const wizardGuard = async (to, from, next) => {
  if (
    (await store.dispatch('auth/isAuthenticated')) === true ||
    (await store.dispatch('auth/loadSession')) === true
  ) {
    if (store.getters['plans/getPlanChosen']) next()
    else next('/plans')
  } else next('/login')
}

const logoutGuard = async (to, from, next) => {
  if ((await store.dispatch('auth/logout')) === true) next('/login')
  else next(false)
}

const prodGuard = async (to, from, next) => {
  // Go back to "from" route, used for preventing
  // navigation to some routes in prod environment.
  if (window.location.hostname.endsWith('.com')) next(from)
  else next()
}

const routes = [
  {
    path: '/',
    redirect: '/plans',
    beforeEnter: authGuard
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/logout',
    beforeEnter: logoutGuard
  },
  {
    path: '/first-time-login',
    name: 'FirstTimeLogin',
    component: () => import('../views/FirstTimeLogin.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword.vue')
  },
  {
    path: '/plans',
    component: () => import('./PlanRoutes.vue'),
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: 'PlanManagement',
        component: () => import('../views/PlanManagement.vue')
      },
      {
        path: 'setup',
        name: 'ConeFocusPlan',
        component: () => import('../views/PlanSetup.vue'),
        beforeEnter: wizardGuard
      },
      {
        path: 'notification',
        name: 'NotificationSetup',
        component: () => import('../views/NotificationSetup.vue'),
        beforeEnter: wizardGuard
      }
    ]
  },
  {
    path: '/networks',
    component: () => import('./NetworkRoutes.vue'),
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: 'NetworkList',
        component: () => import('../views/network-list')
      },
      {
        path: 'config/:clientName?',
        name: 'ClientConfig',
        component: () => import('../views/ClientConfig.vue')
      }
    ]
  },
  {
    path: '/pre-enrollment-custom',
    component: () => import('./PreEnrollmentCustomRoutes.vue'),
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: 'CustomSitesManagement',
        component: () => import('../views/CustomSitesManagement.vue')
      },
      {
        path: 'custom/:clientName?',
        name: 'SiteCustomization',
        component: () => import('../views/SiteCustomization.vue')
      }
    ]
  },
  {
    path: '/notifications-queue',
    beforeEnter: authGuard,
    component: () => import('./NotificationsQueueRoutes.vue'),
    children: [
      {
        path: '',
        name: 'NotificationsQueue',
        component: () => import('../views/NotificationsQueue.vue')
      }
    ]
  },
  {
    path: '/test-members-creation',
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next)
      prodGuard(to, from, next)
    },
    component: () => import('./TestMembersCreationRoutes.vue'),
    children: [
      {
        path: '',
        name: 'TestMembersList',
        component: () => import('../views/TestMembersList.vue')
      },
      {
        path: 'create-edit/:generatedUid?',
        name: 'TestMembersCreateEdit',
        component: () => import('../views/TestMembersCreateEdit.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
