import Vue from 'vue'
import Vuex from 'vuex'

import client from '@/api/networkConfigApi'
import { isSuccessResponse } from '@/helpers/is-success-response'
import { ClientApi } from '@/types/client-api'
import { Client } from '@/types/client-config'

Vue.use(Vuex)

type NetworkConfigModuleState = {
  clientConfigs: ClientApi.Client[]
  clientConfigGrid: Client.ConfigGrid[]
  showNetworkAlertModal: Boolean | String,
  networkHasChanged: Boolean,
  networkSeriesOptions: ClientApi.NetworkSeriesOptions,
  clientNameList: String[]
}

const networkConfigModule: {
  namespaced: boolean,
  state: () => NetworkConfigModuleState,
  mutations: {
    setClientConfigs: (state: NetworkConfigModuleState, clientConfigs: ClientApi.Client[]) => void
    setClientConfigGrid: (state: NetworkConfigModuleState, clientConfigGrid: Client.ConfigGrid[]) => void
    setShowNetworkAlertModal: (state: NetworkConfigModuleState, showNetworkAlertModal: Boolean | String) => void
    setNetworkHasChanged: (state: NetworkConfigModuleState, networkHasChanged: Boolean) => void
    setNetworkSeriesOptions: (state: NetworkConfigModuleState, networkSeriesOptions: ClientApi.NetworkSeriesOptions) => void
    setClientNameList: (state: NetworkConfigModuleState, clientNameList: String[]) => void
  }
  actions: {
    getClientConfigs: (params: { commit: any }) => void
    setShowNetworkAlertModal: (params: { commit: any }, showNetworkAlertModal: Boolean | String) => void
    setNetworkHasChanged: (params: { commit: any }, networkHasChanged: Boolean) => void
    getNetworkSeriesOptions: (params: {commit: any}, networkSeriesOptions: ClientApi.NetworkSeriesOptions) => void
  }
  getters: {
    getClientConfigs: (state: NetworkConfigModuleState) => ClientApi.Client[],
    getClientConfigGrid: (state: NetworkConfigModuleState) => Client.ConfigGrid[]
    getShowNetworkAlertModal: (state: NetworkConfigModuleState) => Boolean | String,
    getNetworkHasChanged: (state: NetworkConfigModuleState) => Boolean,
    getNetworkSeriesOptions: (state: NetworkConfigModuleState) => ClientApi.NetworkSeriesOptions,
    getClientNameList: (state: NetworkConfigModuleState) => String[]
  }
} = {
  namespaced: true,
  state: () => ({
    clientConfigs: [],
    clientConfigGrid: [],
    showNetworkAlertModal: false,
    networkHasChanged: false,
    clientNameList: [],
    networkSeriesOptions: {
      'Core network 100 series': [
        '101',
        '102',
        '103',
        '104'
      ],
      'Wisconsin network 600 series': [
        '601',
        '602',
        '603'
      ],
      '300 series': [
        '301',
        '302',
        '303'
      ],
      '400 series': [
        '401',
        '402',
        '403'
      ],
      '500 series': [
        '501'
      ],
      '700 series': [
        '701',
        '702',
        '703',
        '704',
        '705'
      ],
      '800 series': [
        '801',
        '802',
        '804'
      ],
      '900 series': [
        '901',
        '902',
        '903',
        '904',
        '905',
        '906',
        '907',
        '908',
        '909',
        '910',
        '911',
        '912'
      ]
    }
  }),
  mutations: {
    setClientConfigs (state, clientConfigs) {
      state.clientConfigs = clientConfigs
    },
    setClientConfigGrid (state, clientConfigGrid) {
      state.clientConfigGrid = clientConfigGrid
    },
    setShowNetworkAlertModal (state, showNetworkAlertModal) {
      state.showNetworkAlertModal = showNetworkAlertModal
    },
    setNetworkHasChanged (state, networkHasChanged) {
      state.networkHasChanged = networkHasChanged
    },
    setNetworkSeriesOptions (state, networkSeriesOptions) {
      state.networkSeriesOptions = networkSeriesOptions
    },
    setClientNameList (state, clientNameList) {
      state.clientNameList = clientNameList
    }
  },
  actions: {
    async getClientConfigs ({ commit }) {
      const shouldGetHrpPlans = true
      const response = await client.listClientConfigs(shouldGetHrpPlans)

      if (isSuccessResponse(response)) {
        const clientConfig = response.data

        commit('setClientConfigs', clientConfig)

        const clientNameList = [] as string[]
        const parsedConfigList = clientConfig.map(config => {
          const parsedData = {
            clientName: config.name,
            clientId: config.clientId ?? '',
            oeUrlName: config.preEnrollment?.siteURL ?? 'N/A',
            status: 'N/A'
          }

          clientNameList.push(config.name.toLowerCase())

          if (config.preEnrollment?.siteURL) {
            parsedData.status = config.preEnrollment?.publish ? 'Published' : 'Unpublished'
          }

          return parsedData
        }).sort((a, b) => a.clientName.localeCompare(b.clientName))

        commit('setClientConfigGrid', parsedConfigList)
        commit('setClientNameList', clientNameList)
      }
    },
    setShowNetworkAlertModal ({ commit }, showNetworkAlertModal) {
      commit('setShowNetworkAlertModal', showNetworkAlertModal)
    },
    setNetworkHasChanged ({ commit }, networkHasChanged) {
      commit('setNetworkHasChanged', networkHasChanged)
    },
    async getNetworkSeriesOptions ({ commit }) {
      const response = await client.getNetworkSeries()
      if (isSuccessResponse(response)) {
        const rawNetworkSeries = response.data
        const networkSeriesList: ClientApi.NetworkSeriesOptions = {}
        rawNetworkSeries.forEach(({ name, values }) => {
          networkSeriesList[name] = values
        })
        commit('setNetworkSeriesOptions', networkSeriesList)
      }
    }
  },
  getters: {
    getClientConfigs: state => state.clientConfigs,
    getClientConfigGrid: state => state.clientConfigGrid,
    getShowNetworkAlertModal: state => state.showNetworkAlertModal,
    getNetworkHasChanged: state => state.networkHasChanged,
    getNetworkSeriesOptions: state => state.networkSeriesOptions,
    getClientNameList: state => state.clientNameList
  }
}

export default networkConfigModule
