import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const setupModule = {
  namespaced: true,
  state: () => ({
    setupData: null
  }),
  mutations: {
    setSetupData (state, setupData) {
      state.setupData = setupData
    }
  },
  actions: {
    setSetupData ({ commit, getters, state }, setupData) {
      commit('setSetupData', setupData)
    }
  },
  modules: {
  },
  getters: {
    getSetupData: state => state.setupData
  }
}

export default setupModule
